import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listTestings } from "../../graphql/queries";

const Index = () => {
  async function getUsers() {
    console.log("We are testing");
    try {
      const response = await API.graphql({
        query: listTestings,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  }
  return <div onClick={() => getUsers()}>Test</div>;
};

export default Index;
